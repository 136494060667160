import styled, { css } from 'styled-components'

import { STYLE_SUM_HEADER_BOTTOM_MENU_HEIGHT_PX } from 'constants/styles'

import {
  TLoaderPointsSettings,
  TStyledLoaderPoints,
  TStyledLoaderPointsItem
} from './LoaderPoints.types'

export const LOADER_POINTS_SETTINGS: TLoaderPointsSettings = {
  delay: { start: 0.45, step: 0.15 }
}

export const StyledLoaderPointsWrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${STYLE_SUM_HEADER_BOTTOM_MENU_HEIGHT_PX});
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledLoaderPoints = styled.div<TStyledLoaderPoints>`
  ${(props) => css`
    position: relative;
    width: ${props.size * props.count + props.margin * (props.count - 1)}px;
    height: ${props.size}px;
  `}
`

export const StyledLoaderPointsItem = styled.div<TStyledLoaderPointsItem>`
  ${(props) => {
    const animationDuration = `${
      LOADER_POINTS_SETTINGS.delay.start +
      LOADER_POINTS_SETTINGS.delay.step * 2 * props.count
    }s`

    const scale0 = `${props.scale[0]}`
    const scale50 = `${props.scale[50]}`
    const scale100 = `${props.scale[100]}`

    return css`
      position: absolute;
      top: 0;
      background-color: transparent;
      width: ${props.size}px;
      height: ${props.size}px;
      animation-name: bounce_fountainG;
      -o-animation-name: bounce_fountainG;
      -ms-animation-name: bounce_fountainG;
      -webkit-animation-name: bounce_fountainG;
      -moz-animation-name: bounce_fountainG;
      animation-duration: ${animationDuration};
      -o-animation-duration: ${animationDuration};
      -ms-animation-duration: ${animationDuration};
      -webkit-animation-duration: ${animationDuration};
      -moz-animation-duration: ${animationDuration};
      animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      animation-direction: normal;
      -o-animation-direction: normal;
      -ms-animation-direction: normal;
      -webkit-animation-direction: normal;
      -moz-animation-direction: normal;
      transform: scale(0.3);
      -o-transform: scale(0.3);
      -ms-transform: scale(0.3);
      -webkit-transform: scale(0.3);
      -moz-transform: scale(0.3);
      border-radius: 50%;
      -o-border-radius: 50%;
      -ms-border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;

      left: ${props.orderNum === 0
        ? 0
        : (props.size + props.margin) * props.orderNum}px;
      animation-delay: ${props.delayPoint}s;
      -o-animation-delay: ${props.delayPoint}s;
      -ms-animation-delay: ${props.delayPoint}s;
      -webkit-animation-delay: ${props.delayPoint}s;
      -moz-animation-delay: ${props.delayPoint}s;

      @keyframes bounce_fountainG {
        0% {
          transform: scale(${scale0});
          background-color: ${props.colors.start};
        }

        50% {
          transform: scale(${scale50});
          background-color: ${props.theme.colors.custom.primary[24]};
        }

        100% {
          transform: scale(${scale100});
          background-color: ${props.colors.end};
        }
      }

      @-o-keyframes bounce_fountainG {
        0% {
          -o-transform: scale(${scale0});
          background-color: ${props.colors.start};
        }

        50% {
          transform: scale(${scale50});
          background-color: ${props.theme.colors.custom.primary[24]};
        }

        100% {
          -o-transform: scale(${scale100});
          background-color: ${props.colors.end};
        }
      }

      @-ms-keyframes bounce_fountainG {
        0% {
          -ms-transform: scale(${scale0});
          background-color: ${props.colors.start};
        }

        50% {
          transform: scale(${scale50});
          background-color: ${props.theme.colors.custom.primary[24]};
        }

        100% {
          -ms-transform: scale(${scale100});
          background-color: ${props.colors.end};
        }
      }

      // when we use it, animation does not work in Safari 15.5
      // TODO test it on different devices
      /* @-webkit-keyframes bounce_fountainG {
        0% {
          -webkit-transform: scale(${scale0});
          background-color: ${props.colors.start};
        }

        100% {
          -webkit-transform: scale(${scale100});
          background-color: ${props.colors.end};
        }
      } */

      @-moz-keyframes bounce_fountainG {
        0% {
          -moz-transform: scale(${scale0});
          background-color: ${props.colors.start};
        }

        50% {
          transform: scale(${scale50});
          background-color: ${props.theme.colors.custom.primary[24]};
        }

        100% {
          -moz-transform: scale(${scale100});
          background-color: ${props.colors.end};
        }
      }
    `
  }}
`
