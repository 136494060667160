import React from 'react'
import { Redirect, RouteProps } from 'react-router-dom'

import { TwainGamesScreen } from '../screens/TwainGames'

import { CasinoScreen } from '../screens/Casino'

import {
  MainScreen,
  LiveScreen,
  LiveEventScreen,
  LineSportScreen,
  LineTournamentsScreen,
  LineScreen,
  LineEventScreen,
  CalendarEventsScreen,
  StatisticsScreen,
  ResultsScreen,
  BetGamesScreen,
  LiveGamesScreen,
  BalanceScreen,
  UserScreen,
  PaymentMethodScreen,
  WithdrawMethodScreen,
  BetsHistoryScreen,
  ProfileScreen,
  AboutScreen,
  RefundPolicyScreen,
  LoginScreen,
  HeadToHeadScreen,
  TurboGamesScreen,
  TurboGamesGameScreen,
  SportGamesScreen,
  FavouriteEventsScreen,
  ChangePasswordScreen,
  LanguageScreen,
  SettingsScreen,
  SearchScreen,
  PromotionsAndBonusesScreen,
  PromotionScreen,
  AMLPolicyScreen,
  TermsAndConditionsScreen,
  PrivacyPolicyScreen,
  ResponsibleGamblingScreen,
  Error404Screen,
  NotificationsPageScreens,
  CyberbetScreen,
  VsportGamesScreen,
  VsportGamesGameScreen,
  FriggScreen,
  EventResultsScreen
  // SupportPageScreens
  // FAQScreen,
  // NotificationsPageScreens
} from './imports'

import { ERoutes } from './routes.types'

// This is a static route configuration. It should include all of your top level
// routes, regardless of whether they are going to server render. In fact, you
// can totally point multiple routes to the same component! This is great for
// when you only need to server render a handful of routes and not your entire
// application!
export interface IRoute extends RouteProps {
  name: string
  private?: boolean
  virtualGame?: boolean
}

const routes: Array<IRoute> = [
  {
    path: ERoutes.Root,
    component: MainScreen,
    name: 'Root',
    exact: true
  },
  {
    path: ERoutes.Live,
    component: LiveScreen,
    name: 'Live',
    exact: true
  },
  {
    path: ERoutes.LiveEvent,
    component: LiveEventScreen,
    name: 'LiveEvent',
    exact: true
  },
  {
    path: ERoutes.LiveRedirect,
    component: LiveScreen,
    name: 'Redirect to Live',
    exact: true
  },
  {
    path: ERoutes.LineSports,
    component: LineSportScreen,
    name: 'Line Sports',
    exact: true
  },
  {
    path: ERoutes.LineTournament,
    component: LineTournamentsScreen,
    name: 'Line Tournament',
    exact: true
  },
  {
    path: ERoutes.Line,
    component: LineScreen,
    name: 'Line',
    exact: true
  },
  {
    path: ERoutes.LineEvent,
    component: LineEventScreen,
    name: 'LineEvent',
    exact: true
  },
  {
    path: ERoutes.Calendar,
    component: CalendarEventsScreen,
    name: 'Calendar',
    exact: true
  },
  {
    path: ERoutes.CalendarRedirect,
    component: () => <Redirect to="/calendar/100" />,
    name: 'Redirect to Calendar',
    exact: true
  },
  {
    path: ERoutes.Statistics,
    component: StatisticsScreen,
    name: 'Statistics',
    exact: true
  },
  {
    path: ERoutes.Results,
    component: ResultsScreen,
    name: 'Results',
    exact: true
  },
  {
    path: ERoutes.ResultsEvent,
    component: EventResultsScreen,
    name: 'EventResults',
    exact: true
  },
  {
    path: ERoutes.BetGames,
    component: BetGamesScreen,
    name: 'BetGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.VsportGames,
    component: VsportGamesScreen,
    name: 'Vsport Games',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.VsportGamesGame,
    component: VsportGamesGameScreen,
    name: 'Vsport Game',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.LiveGames,
    component: LiveGamesScreen,
    name: 'LiveGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TwainGames,
    component: TwainGamesScreen,
    name: 'TwainGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.Casino,
    component: CasinoScreen,
    name: 'Casino',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.VirtualGames,
    name: 'VirtualGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TopUp,
    component: BalanceScreen,
    name: 'TopUp',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Withdraw,
    component: BalanceScreen,
    name: 'Withdraw',
    exact: true,
    private: true
  },
  {
    path: ERoutes.BalanceHistory,
    component: BalanceScreen,
    name: 'BalanceHistory',
    exact: true,
    private: true
  },
  {
    path: ERoutes.User,
    component: UserScreen,
    name: 'User',
    exact: true,
    private: true
  },
  {
    path: ERoutes.TopUpMethod,
    component: PaymentMethodScreen,
    name: 'TopUpMethod',
    exact: true,
    private: true
  },
  {
    path: ERoutes.WithdrawMethod,
    component: WithdrawMethodScreen,
    name: 'WithdrawMethod',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Exit,
    name: 'Exit',
    exact: true
  },
  {
    path: ERoutes.BetsHistory,
    component: BetsHistoryScreen,
    name: 'BetsHistory',
    exact: true
  },
  {
    path: ERoutes.Profile,
    component: ProfileScreen,
    name: 'Profile',
    exact: true
  },
  {
    path: ERoutes.Identification,
    name: 'Identification',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Won,
    name: 'Won',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Notifications,
    name: 'Notifications',
    exact: true,
    private: true
  },
  {
    path: ERoutes.Promotion,
    component: PromotionScreen,
    name: 'Promotion',
    exact: true,
    private: true
  },
  {
    path: ERoutes.PromotionsAndBonuses,
    component: PromotionsAndBonusesScreen,
    name: 'PromotionsAndBonuses',
    exact: true,
    private: true
  },
  // {    TODO integrate
  //   path: ERoutes.FAQ,
  //   component: FAQScreen,
  //   name: 'FAQ',
  //   exact: true
  // },
  {
    path: ERoutes.NotificationsPage,
    component: NotificationsPageScreens,
    name: 'NotificationsPage',
    exact: true
  },
  // {    TODO integrate
  //   path: ERoutes.SupportPage,
  //   component: SupportPageScreens,
  //   name: 'SupportPage',
  //   exact: true
  // },
  {
    path: ERoutes.About,
    component: AboutScreen,
    name: 'About',
    exact: true
  },
  {
    path: ERoutes.Support,
    name: 'Support',
    exact: true
  },
  {
    path: ERoutes.Rules,
    render: () => <Redirect to="/about" />,
    name: 'Rules',
    exact: true
  },
  {
    path: ERoutes.Training,
    name: 'Training',
    exact: true
  },
  {
    path: ERoutes.PrivacyPolicy,
    component: PrivacyPolicyScreen,
    name: 'Privacy Policy',
    exact: true
  },
  {
    path: ERoutes.RefundPolicy,
    component: RefundPolicyScreen,
    name: 'Refund Policy',
    exact: true
  },
  {
    path: ERoutes.AMLPolicy,
    component: AMLPolicyScreen,
    name: 'AML Policy',
    exact: true
  },
  {
    path: ERoutes.TermsAndConditions,
    component: TermsAndConditionsScreen,
    name: 'Terms and Conditions',
    exact: true
  },
  {
    path: ERoutes.ResponsibleGambling,
    component: ResponsibleGamblingScreen,
    name: 'Responsible Gambling',
    exact: true
  },
  {
    path: ERoutes.Login,
    component: LoginScreen,
    name: 'Login',
    exact: true
  },
  {
    path: ERoutes.HeadToHead,
    component: HeadToHeadScreen,
    name: 'Head 2 Head statistics Page',
    exact: true
  },
  {
    path: ERoutes.TurboGames,
    component: TurboGamesScreen,
    name: 'TurboGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.TurboGamesGame,
    component: TurboGamesGameScreen,
    name: 'TurboGamesGame',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.SportGamesLobby,
    component: SportGamesScreen,
    name: 'SportGames',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.FavouriteEvents,
    component: FavouriteEventsScreen,
    name: 'Favourite events',
    exact: true,
    private: true
  },
  {
    path: ERoutes.ChangePassword,
    component: ChangePasswordScreen,
    name: 'Change Password',
    exact: true,
    private: true
  },
  {
    path: ERoutes.SettingsChangeLanguage,
    component: LanguageScreen,
    name: 'Language',
    exact: true
  },
  {
    path: ERoutes.Settings,
    component: SettingsScreen,
    name: 'Settings',
    exact: true
  },
  // TODO: Remove after PLAT-1060 test and release
  // {
  //   path: ERoutes.SuccessLoginPage,
  //   component: SuccessPageScreen,
  //   name: 'Success Page',
  //   exact: true
  // },
  // {
  //   path: ERoutes.SuccessFastSignUp,
  //   component: SuccessPageScreen,
  //   name: 'Success Fast Sign Up',
  //   exact: true
  // },
  // {
  //   path: ERoutes.SuccessFullSignUp,
  //   component: SuccessPageScreen,
  //   name: 'Success Full Sign Up',
  //   exact: true
  // },
  {
    path: ERoutes.Search,
    component: SearchScreen,
    name: 'Search page',
    exact: true
  },
  {
    path: ERoutes.CyberbetGames,
    component: CyberbetScreen,
    name: 'Cyberbet',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.Frigg,
    component: FriggScreen,
    name: 'Frigg',
    exact: true,
    virtualGame: true
  },
  {
    path: ERoutes.Error404,
    component: Error404Screen,
    name: 'Error 404',
    exact: true
  }
]

export default routes

export const virtualGamesRoutes = routes.reduce<Set<ERoutes>>((acc, route) => {
  if (route.virtualGame) {
    acc.add(route.path as ERoutes)
  }
  return acc
}, new Set())

/**
 * That kind of routes used for iframe redirect during sing in / sign up process
 */
export const iFrameRoutes = [
  ERoutes.SuccessLoginPage,
  ERoutes.SuccessFastSignUp,
  ERoutes.SuccessFullSignUp
]
