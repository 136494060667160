import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { DEVICE } from 'components/Header/consts'

export const PanelWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  margin: auto 0;
`
export const PanelContent = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.default.primary[90]};
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const PanelTypography = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.l};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  padding: 8px 0 8px 0;
  text-align: center;

  @media ${DEVICE.mobileS} {
    max-width: 310px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`

export const PanelTypographyLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.custom.primary[4]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.custom.primary[13]};
  font-size: ${({ theme }) => theme.fonts.size.s};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.default.primary[10]};
  }
`
