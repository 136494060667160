import React, { FC, memo } from 'react'

import {
  DEFAULT_STYLED_ICON_BUTTON_PROPS,
  DEFAULT_COLOR_PROPS
} from './constants'
import { IconProps } from './Icon.types'
import { StyledIcon, StyledIconButton } from './Icon.styled'

export const Icon: FC<IconProps> = memo(
  ({ children, buttonProps, testData, onClick, ...props }) => {
    if (buttonProps) {
      return (
        <StyledIconButton
          {...DEFAULT_STYLED_ICON_BUTTON_PROPS}
          {...(typeof buttonProps === 'object' && buttonProps)}
          onClick={onClick}
        >
          <StyledIcon
            colorProps={DEFAULT_COLOR_PROPS}
            size={props.size ?? 14}
            {...props}
          >
            {children}
          </StyledIcon>
        </StyledIconButton>
      )
    } else {
      return (
        <StyledIcon
          colorProps={DEFAULT_COLOR_PROPS}
          size={props.size ?? 14}
          data-test-id={testData}
          onClick={onClick}
          {...props}
        >
          {children}
        </StyledIcon>
      )
    }
  }
)
