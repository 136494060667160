import {
  EColorsNames,
  EColorsTypes
} from 'components/ui/ThemeProvider/ThemeProvider.types'

import {
  EIconButtonShape,
  EIconButtonSize,
  IIconPropsButton
} from './Icon.types'

export const DEFAULT_STYLED_ICON_BUTTON_PROPS: IIconPropsButton = {
  isActiveState: false,
  shape: EIconButtonShape.Default,
  withBackground: true,
  size: EIconButtonSize.M
}

export const DEFAULT_COLOR_PROPS = {
  name: EColorsNames.Primary,
  value: 80,
  type: EColorsTypes.DEFAULT
}
