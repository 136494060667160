import { HTMLAttributes } from 'react'

import { ETestData } from 'utils/testData'

import { TComponentColorProps } from 'components/ui/ThemeProvider/ThemeProvider.types'

type TCustomSize = { width: number; height: number }

export interface IconProps {
  size?: number | TCustomSize // px
  buttonProps?: Partial<IIconPropsButton> | true
  twist?: number // rotate using deg
  colorProps?: TComponentColorProps
  onClick?: HTMLAttributes<HTMLSpanElement | HTMLButtonElement>['onClick']
  isActive?: boolean
  additionalColor?: `#${string}` | `rgb${string}`
  testData?: ETestData
  color?: string
}

export enum EServiceIconPostion {
  HEADER = 'header',
  MENU = 'menu',
  HISTORY = 'history'
}
export interface IServiceIconProps extends IconProps {
  position?: EServiceIconPostion
}

export type TIconPropsAdditionalCssProps = {
  withHover?: boolean
  withActive?: boolean
  $isActiveState?: boolean
}

export interface IIconPropsButton {
  shape: EIconButtonShape
  size: EIconButtonSize
  withBackground: boolean
  isActiveState: boolean
}

export enum EIconButtonShape {
  Circle = 'circle',
  Default = 'default'
}

export enum EIconButtonSize {
  M = '24px',
  L = '32px'
}

export interface IStyledIconButtonProps extends IIconPropsButton {}

export type IconWithAdditionalCssProps = IconProps &
  TIconPropsAdditionalCssProps
