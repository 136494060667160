/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { hydrate } from 'react-dom'
import { loadableReady } from '@loadable/component'

import axios from 'axios'

import ApiBuilder from 'astra-core/config/ApiBuilder'

import CoreConfiguration from 'astra-core/config/CoreConfiguration'

import { addLogoutInterceptor } from 'og-web-core/shared/axios/interceptors'
import { addCaptchaInterceptor } from 'og-web-core/shared/axios/addCaptchaInterceptor'

import { configureAppStore } from 'store/configureStore'

import './i18n'
import { virtualGamesRoutes } from './routes'
import { initSentryClient } from './utils/sentry/client'

/*
Redux Store Init
Statically injected reducers are pre-filled with initial data here,
while initial states of dynamically injected reducers are pre-filled
on behalf of each particular slice.
 */
const store = configureAppStore({
  preloadedState: window.__PRELOADED_STATE__
})

const axiosInstance = axios.create()
// @ts-ignore
addLogoutInterceptor(axiosInstance, store, virtualGamesRoutes)
// @ts-ignore
addCaptchaInterceptor(axiosInstance, store)

// Init core configuration
CoreConfiguration.init({
  apiBuilder: ApiBuilder.create(axiosInstance)
})

// Init Sentry Client
initSentryClient()

// Log APP_VERSION
const appVersion = window.__PRELOADED_STATE__.configProvider.config.APP_VERSION
if (appVersion) {
  console.log('APP_VERSION', appVersion)
}

async function enableMocking() {
  if (process.env.RAZZLE_VTEST === 'test') {
    const { startWorker } = await import('astrabet-ui-tests/web')

    return startWorker('/api')
  }
}

// Load all components needed before rendering
enableMocking().then(() =>
  loadableReady().then(() => {
    import('AppContainer').then(({ AppContainer }) =>
      hydrate(<AppContainer store={store} />, document.getElementById('root'))
    )
  })
)

if (module.hot) {
  module.hot.accept()
}
