import styled, { css } from 'styled-components'

import {
  EColorsNames,
  EColorsTypes
} from 'components/ui/ThemeProvider/ThemeProvider.types'

import { CssButtonReset } from 'constants/styles/index'

import {
  EIconButtonShape,
  IconProps,
  IStyledIconButtonProps
} from './Icon.types'

export const StyledIcon = styled.span<IconProps>`
  ${(props) => {
    const iconColor = props.colorProps
      ? props.theme.colors[props.colorProps.type || EColorsTypes.DEFAULT][
          props.colorProps.name
        ][props.colorProps.value]
      : ''

    return css`
      display: inline-flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      svg {
        width: 100%;
        height: 100%;
      }

      ${props.colorProps &&
      iconColor &&
      css`
        color: ${iconColor} !important;
      `}

      ${typeof props.size === 'number' &&
      css`
        width: ${props.size}px;
        height: ${props.size}px;
      `}

    ${typeof props.size === 'object' &&
      css`
        width: ${props.size.width}px;
        height: ${props.size.height}px;
      `}

    ${props.twist &&
      css`
        transform: rotate(${props.twist}deg);
      `}

    ${props.onClick &&
      css`
        cursor: pointer;
        ${CssButtonReset}
      `}
    `
  }}
`

export const StyledIconPreloader = styled.div`
  height: 40px;
  width: 40px;
  transform-origin: 50% 50%;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
`

export const StyledIconButton = styled.button<IStyledIconButtonProps>`
  ${(props) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: ${props.size};
    width: ${props.size};
    outline: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    ${props.shape === EIconButtonShape.Circle &&
    css`
      border-radius: 50%;
    `}

    ${props.isActiveState
      ? css`
          background: ${props.withBackground
            ? props.theme.colors.default[EColorsNames.Primary][90]
            : 'transparent'};

          ${StyledIcon} {
            color: ${props.theme.colors.default[
              EColorsNames.Primary
            ][0]} !important;
          }

          &:active {
            background: ${props.theme.colors.default[EColorsNames.Primary][70]};
          }
        `
      : css`
          background: ${props.withBackground
            ? props.theme.colors.default[EColorsNames.Primary][5]
            : 'transparent'};

          ${StyledIcon} {
            color: ${props.theme.colors.default[
              EColorsNames.Primary
            ][50]} !important;
          }

          &:active {
            background: ${props.theme.colors.default[EColorsNames.Primary][5]};

            ${StyledIcon} {
              color: ${props.theme.colors.default[
                EColorsNames.Primary
              ][50]} !important;
            }
          }
        `}
  `}
`

export const iconEmphasizedColor = {
  0: '#FAF2EA',
  100: '#EBFAEB',
  101: '#E8FCEF',
  102: '#FFF9E5',
  103: '#F2F2F2',
  110: '#FCE8EF'
}
