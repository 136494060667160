import React from 'react'

import loadable from '@loadable/component'

import { StyledLoaderPoints } from 'components/Routes/AppRoute.styled'
import { LoaderPoints } from 'components/ui/Loader'

const fallback = (
  <StyledLoaderPoints>
    <LoaderPoints />
  </StyledLoaderPoints>
)

export const AboutScreen = loadable(() => import('screens/About'), {
  resolveComponent: (components) => components.AboutScreen,
  fallback
})

export const BalanceScreen = loadable(() => import('screens/Balance'), {
  resolveComponent: (components) => components.BalanceScreen,
  fallback
})

export const BetGamesScreen = loadable(() => import('screens/BetGames'), {
  resolveComponent: (components) => components.BetGamesScreen,
  fallback
})

export const VsportGamesScreen = loadable(() => import('screens/VsportGames'), {
  resolveComponent: (components) => components.PageGamesVsport,
  fallback
})

export const VsportGamesGameScreen = loadable(
  () => import('screens/VsportGamesGame'),
  {
    resolveComponent: (components) => components.PageGamesVsportGame,
    fallback
  }
)

export const BetsHistoryScreen = loadable(() => import('screens/BetsHistory'), {
  resolveComponent: (components) => components.BetsHistoryScreen,
  fallback
})

export const CalendarEventsScreen = loadable(
  () => import('screens/CalendarEvents'),
  {
    resolveComponent: (components) => components.CalendarEventsScreen,
    fallback
  }
)

export const ChangePasswordScreen = loadable(
  () => import('screens/ChangePassword'),
  {
    resolveComponent: (components) => components.ChangePasswordScreen,
    fallback
  }
)

export const FavouriteEventsScreen = loadable(
  () => import('screens/FavouriteEvents'),
  {
    resolveComponent: (components) => components.FavouriteEventsScreen,
    fallback
  }
)

export const HeadToHeadScreen = loadable(() => import('screens/HeadToHead'), {
  resolveComponent: (components) => components.HeadToHeadScreen,
  fallback
})

export const LanguageScreen = loadable(() => import('screens/Language'), {
  resolveComponent: (components) => components.LanguageScreen,
  fallback
})

export const LineScreen = loadable(() => import('screens/Line'), {
  resolveComponent: (components) => components.LineScreen,
  fallback
})

export const LineEventScreen = loadable(() => import('screens/LineEvent'), {
  resolveComponent: (components) => components.LineEventScreen,
  fallback
})

export const LineSportScreen = loadable(() => import('screens/LineSport'), {
  resolveComponent: (components) => components.LineSportScreen,
  fallback
})

export const LineTournamentsScreen = loadable(
  () => import('screens/LineTournaments'),
  {
    resolveComponent: (components) => components.LineTournamentsScreen,
    fallback
  }
)

export const LiveScreen = loadable(() => import('screens/Live'), {
  resolveComponent: (components) => components.LiveScreen,
  fallback
})

export const LiveEventScreen = loadable(() => import('screens/LiveEvent'), {
  resolveComponent: (components) => components.LiveEventScreen,
  fallback
})

export const LiveGamesScreen = loadable(() => import('screens/LiveGames'), {
  resolveComponent: (components) => components.LiveGamesScreen,
  fallback
})

export const LoginScreen = loadable(() => import('screens/Login'), {
  resolveComponent: (components) => components.LoginScreen,
  fallback
})

export const MainScreen = loadable(() => import('screens/Main'), {
  resolveComponent: (components) => components.MainScreen,
  fallback
})

export const PaymentMethodScreen = loadable(
  () => import('screens/PaymentMethod'),
  {
    resolveComponent: (components) => components.PaymentMethodScreen,
    fallback
  }
)

export const ProfileScreen = loadable(() => import('screens/Profile'), {
  resolveComponent: (components) => components.ProfileScreen,
  fallback
})

export const RefundPolicyScreen = loadable(
  () => import('screens/RefundPolicy'),
  { resolveComponent: (components) => components.RefundPolicyScreen, fallback }
)

export const AMLPolicyScreen = loadable(() => import('screens/AMLPolicy'), {
  resolveComponent: (components) => components.AMLPolicyScreen,
  fallback
})

export const TermsAndConditionsScreen = loadable(
  () => import('screens/TermsAndConditions'),
  {
    resolveComponent: (components) => components.TermsAndConditionsScreen,
    fallback
  }
)

export const ResponsibleGamblingScreen = loadable(
  () => import('screens/ResponsibleGambling'),
  {
    resolveComponent: (components) => components.ResponsibleGamblingScreen,
    fallback
  }
)

export const PrivacyPolicyScreen = loadable(
  () => import('screens/PrivacyPolicy'),
  {
    resolveComponent: (components) => components.PrivacyPolicyScreen,
    fallback
  }
)

export const ResultsScreen = loadable(() => import('screens/Results'), {
  resolveComponent: (components) => components.ResultsScreen,
  fallback
})

export const EventResultsScreen = loadable(
  () => import('screens/EventResults'),
  {
    resolveComponent: (components) => components.EventResultsScreen,
    fallback
  }
)

export const SettingsScreen = loadable(() => import('screens/Settings'), {
  resolveComponent: (components) => components.SettingsScreen,
  fallback
})

export const SportGamesScreen = loadable(() => import('screens/SportGames'), {
  resolveComponent: (components) => components.SportGamesScreen,
  fallback
})

export const StatisticsScreen = loadable(() => import('screens/Statistics'), {
  resolveComponent: (components) => components.StatisticsScreen,
  fallback
})

export const TurboGamesScreen = loadable(() => import('screens/TurboGames'), {
  resolveComponent: (components) => components.TurboGamesScreen,
  fallback
})

export const TurboGamesGameScreen = loadable(
  () => import('screens/TurboGamesGame'),
  {
    resolveComponent: (components) => components.TurboGamesGameScreen,
    fallback
  }
)

export const UserScreen = loadable(() => import('screens/User'), {
  resolveComponent: (components) => components.UserScreen,
  fallback
})

export const WithdrawMethodScreen = loadable(
  () => import('screens/WithdrawMethod'),
  {
    resolveComponent: (components) => components.WithdrawMethodScreen,
    fallback
  }
)

// TODO: Remove after PLAT-1060  test and release
// export const SuccessPageScreen = loadable(
//   () => import('components/SuccessPage'),
//   {
//     resolveComponent: (components) => components.SuccessPage,
//     fallback
//   }
// )

export const SearchScreen = loadable(() => import('screens/Search'), {
  resolveComponent: (components) => components.SearchScreen,
  fallback
})

export const PromotionsAndBonusesScreen = loadable(
  () => import('screens/PromotionsAndBonuses'),
  {
    resolveComponent: (components) => components.PromotionsAndBonusesScreen,
    fallback
  }
)
export const FAQScreen = loadable(() => import('screens/FAQ'), {
  resolveComponent: (components) => components.FAQScreen,
  fallback
})

export const NotificationsPageScreens = loadable(
  () => import('screens/NotificationsPage'),
  {
    resolveComponent: (components) => components.NotificationsPageScreens,
    fallback
  }
)

export const SupportPageScreens = loadable(
  () => import('screens/SupportPage'),
  {
    resolveComponent: (components) => components.SupportPageScreens,
    fallback
  }
)

export const PromotionScreen = loadable(() => import('screens/Promotion'), {
  resolveComponent: (components) => components.PromotionScreen,
  fallback
})

export const Error404Screen = loadable(() => import('screens/Error404'), {
  resolveComponent: (components) => components.Error404Screen,
  fallback
})

export const OfflineScreen = loadable(() => import('screens/OfflinePage'), {
  resolveComponent: (components) => components.OfflineScreen,
  fallback
})

export const CyberbetScreen = loadable(() => import('screens/Cyberbet'), {
  resolveComponent: (components) => components.CyberbetScreen,
  fallback
})

export const FriggScreen = loadable(() => import('screens/Frigg'), {
  resolveComponent: (components) => components.FriggScreen,
  fallback
})
