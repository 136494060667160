import React, { FC, useMemo } from 'react'

import { useTheme } from 'styled-components'

import { isEqualDepth } from 'astra-core/utils'

import { range } from 'utils/functions'

import {
  LOADER_POINTS_SETTINGS,
  StyledLoaderPoints,
  StyledLoaderPointsItem,
  StyledLoaderPointsWrapper
} from './LoaderPoints.styled'
import {
  ELoaderPointsPositions,
  ILoaderPointsProps
} from './LoaderPoints.types'
import { DEFAULT_SCALE } from './constants'

export const LoaderPoints: FC<ILoaderPointsProps> = React.memo(
  ({
    size = 8,
    margin = 5,
    count = 5,
    colors,
    position,
    scale,
    settings = LOADER_POINTS_SETTINGS
  }) => {
    const theme = useTheme()

    const loader = useMemo(() => {
      const fakeArray = range(count)
      let delay = settings.delay.start
      const selectedColors = colors || {
        start: theme.colors.custom.primary[0],
        end: theme.colors.default.primary[0]
      }
      const selectedScale = scale || DEFAULT_SCALE

      return (
        <StyledLoaderPoints count={count} margin={margin} size={size}>
          {fakeArray.map((num) => {
            delay += settings.delay.step

            return (
              <StyledLoaderPointsItem
                colors={selectedColors}
                count={count}
                delayPoint={delay}
                key={num}
                margin={margin}
                orderNum={num}
                size={size}
                scale={selectedScale}
              />
            )
          })}
        </StyledLoaderPoints>
      )
    }, [
      colors,
      count,
      margin,
      size,
      theme.colors.custom.primary,
      theme.colors.default.primary,
      scale,
      settings
    ])

    return position === ELoaderPointsPositions.CENTER ? (
      <StyledLoaderPointsWrapper>{loader}</StyledLoaderPointsWrapper>
    ) : (
      loader
    )
  },
  isEqualDepth
)
