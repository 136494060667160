import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { useTwainGamesData } from 'og-core/providers/virtual-games/hooks'

import { LoaderPoints } from 'components/ui/Loader'
import { ELoaderPointsPositions } from 'components/ui/Loader/components/LoaderPoints/LoaderPoints.types'

import { StyledTwainGames } from './TwainGames.styled'

export const TwainGames: FC = () => {
  const { loaded, token } = useTwainGamesData()

  return loaded ? (
    <>
      <LiveGamesContainer token={token} />
    </>
  ) : (
    <LoaderPoints position={ELoaderPointsPositions.CENTER} />
  )
}

const TWAIN_GAMES_CONTAINER_ID = 'twain_sport_container'

const LiveGamesContainer = ({ token }: { token: Maybe<string> }) => {
  const { i18n } = useTranslation()
  const config = useSelector(selectConfig)
  const isBalanceHidden = useSelector((state) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (isInitialized) {
      return
    }
    const containerElement = document.getElementById(TWAIN_GAMES_CONTAINER_ID)
    const script = document.createElement('script')

    script.src = `${
      config.TWAIN_GAMES_CLIENT_URL
    }/public/ts-loader.js?${Date.now()}`
    script.onload = () => {
      window.TwainSports.setup({
        containerElement,
        clientUrl: config.TWAIN_GAMES_CLIENT_URL,
        apiUrl: config.TWAIN_GAMES_API_URL,
        wsUrl: config.TWAIN_GAMES_WS_URL,
        partnerCode: config.TWAIN_GAMES_PARTNER_CODE,
        token: token ?? '-',
        locale: i18n.languages[0] || 'en',
        showBalance: 0,
        theme: 'dark',
        timezone: dayjs().format('Z'),
        integration: 'embedded',
        showLogo: 0
      })
    }
    setIsInitialized(true)

    document.head.appendChild(script)
  }, [
    isInitialized,
    config.TWAIN_GAMES_API_URL,
    config.TWAIN_GAMES_CLIENT_URL,
    config.TWAIN_GAMES_PARTNER_CODE,
    config.TWAIN_GAMES_WS_URL,
    i18n.languages,
    isBalanceHidden,
    token
  ])

  return <StyledTwainGames id={TWAIN_GAMES_CONTAINER_ID} />
}
