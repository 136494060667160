export const STYLE_MAIN_HEADER_HEIGHT_PX = '40px'
export const STYLE_MAIN_BOTTOM_MENU_HEIGHT_PX = '64px'
export const STYLE_BASKET_BET_PANEL_PX = '64px'
export const STYLE_BASKET_BET_PANEL_HEIGHT_PX = '49px'
export const STYLE_BASKET_BET_BOTTOM_MENU_HEIGHT_PX = '115px'
export const STYLE_RESULTS_HEADER_HEIGHT_PX = '213px'
export const STYLE_RESULTS_CHAMPIONSHIP_NAME = '257px'
export const STYLE_BETS_HISTORY_HEADER_HEIGHT_PX = '245px'
export const STYLE_STICKY_DATE_INDENTATION_LINE = '153px'
export const STYLE_STICKY_EVENT_NAME_INDENTATION_LINE = '128px'
export const STYLE_STICKY_EVENT_NAME_INDENTATION_LIVE = '175px'
export const STYLE_STICKY_EVENT_NAME_CALENDAR = '144px'
export const STYLE_STICKY_DATE_CALENDAR = '161px'
export const STYLE_DOWNLOAD_APP_BANNER_HEIGHT = '76px'

export const STYLE_SUM_HEADER_BOTTOM_MENU_HEIGHT_PX = `${
  parseInt(STYLE_MAIN_HEADER_HEIGHT_PX, 10) +
  parseInt(STYLE_MAIN_BOTTOM_MENU_HEIGHT_PX, 10)
}px`
