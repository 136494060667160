export interface ILoaderPointsProps {
  size?: number
  margin?: number
  count?: number
  colors?: {
    start: string
    end: string
  }
  position?: ELoaderPointsPositions
  scale?: {
    '0': number
    '50': number
    '100': number
  }
  settings?: TLoaderPointsSettings
}

export enum ELoaderPointsPositions {
  CENTER = 'center'
}

export type TStyledLoaderPoints = Required<
  Pick<ILoaderPointsProps, 'size' | 'margin' | 'count'>
>

export type TStyledLoaderPointsItem = Required<
  Omit<ILoaderPointsProps, 'position' | 'settings'>
> & {
  delayPoint: number
  orderNum: number
}

export interface IStyledLoadCircleProps extends ILoaderPointsProps {}

export type TLoaderPointsSettings = {
  delay: {
    start: number
    step: number
  }
}
