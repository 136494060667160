import React from 'react'

import { VirtualGameVendor } from 'betweb-openapi-axios'

import { TwainGames } from 'components/TwainGames'
import { ServiceAvailability } from 'components/ServiceAvailability'

export const TwainGamesScreen = () => {
  return (
    <ServiceAvailability vendor={VirtualGameVendor.TwainSport}>
      <TwainGames />
    </ServiceAvailability>
  )
}
