import React, { FC } from 'react'

import { useSelector } from 'react-redux'

import { selectGameVendorSettingsStatus } from 'astra-core/containers/GameVendorSettingsProvider'

import { LoaderPoints } from 'components/ui/Loader'

import { AvailablePanel } from '../AvailablePanel'

import { TServiceAvailabilityProps } from './ServiceAvailability.types'

export const ServiceAvailability: FC<TServiceAvailabilityProps> = ({
  vendor,
  children
}) => {
  const { loading, enabled } = useSelector(
    selectGameVendorSettingsStatus(vendor)
  )

  if (loading) {
    return <LoaderPoints />
  }

  if (enabled === false) {
    return <AvailablePanel />
  }

  return <>{children}</>
}
