export enum ERoutes {
  About = '/profile/about',
  Login = '/login',
  BalanceHistory = '/profile/history',
  BetsHistory = '/profile/bets-history',
  BetGames = '/betgames',
  CyberbetGames = '/cyberbet',
  TurboGames = '/turbo-games',
  TurboGamesGame = '/turbo-games/:gameNameId',
  SportGamesLobby = '/sport-games/sport-games-lobby',
  Calendar = '/calendar/:sportId',
  CalendarRedirect = '/calendar',
  Casino = '/casino',
  Exit = '#', // TOTO remove this route /profile/exit
  Identification = '/profile/identification',
  Live = '/live/:sportId',
  LiveRedirect = '/live',
  LiveEvent = '/live/:sportId/:eventId',
  Line = '/line/:sportId/:tournamentId',
  LineSports = '/line',
  LineTournament = '/line/:sportId',
  LineEvent = '/line/:sportId/:tournamentId/:eventId',
  LiveGames = '/livegames',
  TwainGames = '/twain-games',
  Notifications = '/profile/notifications',
  PromotionsAndBonuses = '/profile/promotions-and-bonuses',
  Promotion = '/profile/promotions-and-bonuses/:id',
  FAQ = '/profile/FAQ',
  NotificationsPage = '/profile/notifications',
  SupportPage = '/profile/support-page',
  PrivacyPolicy = '/privacy-policy',
  Profile = '/profile',
  RefundPolicy = '/refund-policy',
  AMLPolicy = '/aml-policy',
  ResponsibleGambling = '/responsible-gambling',
  TermsAndConditions = '/terms-and-conditions',
  Results = '/results',
  ResultsEvent = '/results/:sportId/:eventId',
  VirtualGames = '/virtual-games',
  Rules = '/rules',
  Root = '/',
  Settings = '/profile/settings',
  SettingsChangeLanguage = '/settings/language',
  Statistics = '/results/statistics',
  SuccessLoginPage = '/auth/success',
  SuccessFastSignUp = '/auth/fast-sign-up-success',
  SuccessFullSignUp = '/auth/full-sign-up-success',
  Support = '/profile/support',
  TopUpMethod = '/profile/top-up/:paymentMethodId',
  Training = '/training',
  TopUp = '/profile/top-up',
  Withdraw = '/profile/withdraw',
  WithdrawMethod = '/profile/withdraw/:withdrawMethodId',
  Won = '/profile/won',
  User = '/profile/user',
  HeadToHead = '/headToHead/:sourceEventId',
  HeadToHeadRedirect = '/headToHead',
  FavouriteEvents = '/favourite',
  ChangePassword = '/profile/changepassword',
  Search = '/search',
  Error404 = '/not-found',
  VsportGames = '/vsport',
  VsportGamesGame = '/vsport/:gameNameId',
  Frigg = '/frigg'
}
