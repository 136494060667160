import React from 'react'

import { useCasinoData } from 'og-core/providers/virtual-games/hooks/useCasinoData'

import { LoaderPoints } from 'components/ui/Loader'
import { ELoaderPointsPositions } from 'components/ui/Loader/components/LoaderPoints/LoaderPoints.types'

export const Casino = () => {
  const { loaded, src } = useCasinoData()

  return loaded ? (
    <CasinoIframe src={src} />
  ) : (
    <LoaderPoints position={ELoaderPointsPositions.CENTER} />
  )
}

const CasinoIframe = ({ src }: { src: string }) => {
  return (
    <iframe
      title="casino-iframe"
      src={src}
      style={{
        width: '100%',
        flex: 1,
        border: 'none',
        outline: 'none',
        font: 'inherit',
        verticalAlign: 'baseline'
      }}
    />
  )
}
